import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { useWindowSize } from '@react-hook/window-size/throttled';

import { SmartLink } from '../SmartLink';
import PeopleCard from '../Cards/PeopleCard';

import style from './RelatedCarousel.module.scss';

const RelatedCarousel = props => {
    const { title, data, cta } = props;
    const sliderEl = useRef(null);
    const wrapRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const arrowsRef = useRef(null);

    const sortDate = (a, b) => {
        return new Date(b.publishingDate) - new Date(a.publishingDate);
    };

    const sliderParams = {
        slidesToShow: 1,
        // arrows: false,
        prevArrow: (
            <button className={style.prevBtn} ref={prevRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        nextArrow: (
            <button className={style.nextBtn} ref={nextRef}>
                <span className='icon-arrow-right' />
            </button>
        ),
        infinite: false,
        speed: 700,
        draggable: true,
        swipe: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 899,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
        onInit: () => {
            // const fragment = document.createDocumentFragment();
            // fragment.appendChild(prevRef.current);
            // fragment.appendChild(nextRef.current);
            // if (arrowsRef.current.children.length > 0) {
            //     arrowsRef.current.innerHTML = '';
            // }
            // arrowsRef.current.appendChild(fragment);
        },
    };

    const wSize = useWindowSize();

    useEffect(() => {
        const arrH = prevRef.current?.offsetHeight;
        arrowsRef.current.style.height = `${arrH}px`;
        const arrowsBound = arrowsRef.current.getBoundingClientRect();
        const wrapBound = wrapRef.current.firstElementChild.getBoundingClientRect();
        const diffX = arrowsBound.right - wrapBound.right;
        const diffY = arrowsBound.top - wrapBound.top;
        if (prevRef.current && nextRef.current) {
            prevRef.current.style.top = `${diffY}px`;
            prevRef.current.style.right = `${-diffX + 40}px`;
            nextRef.current.style.top = `${diffY}px`;
            nextRef.current.style.right = `${diffX * -1}px`;
        }
    }, [wSize]);

    return (
        <div className={[style.el, 'related-carousel'].join(' ')}>
            <div className={[style.container, 'container'].join(' ')}>
                <div className={style.slickArrows} ref={arrowsRef} />
                <div className={style.title}>
                    <h2>{title}</h2>
                </div>
                <div className={style.wrapper} ref={wrapRef}>
                    <Slider {...sliderParams} ref={sliderEl}>
                        {data.length &&
                            data.sort(sortDate).map((item, i) => (
                                <div className={style.peopleCard} key={`card${i}`}>
                                    <PeopleCard {...item} />
                                </div>
                            ))}
                    </Slider>
                </div>
                <div className={style.cta}>
                    <div className={style.ctaLink}>
                        <SmartLink href={cta.link.url} hasArrow className='arrow-link'>
                            {cta.text}
                        </SmartLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

RelatedCarousel.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.object,
    }),
};

RelatedCarousel.defaultProps = {};

export default RelatedCarousel;
